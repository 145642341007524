import * as React from "react";
import "../../styles/utils/footer.scss";
import logoHouzz from "../../images/houzz.svg";
import logoFacebook from "../../images/facebook.svg";
import logo from "../../images/logo.svg";
import eventBus from "../utils/eventBus.js";
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";

const Footer = () => {
  return (
    <footer>
      <div className="footer-left">
        <div className="back"></div>
        <div className="front">
          <picture>
            <img src={logo} alt="Logo de Perret Menuiserie" />
          </picture>
          <p>© PERRET S.A.S.</p>
        </div>
      </div>
      <div className="footer-right">
        <div className="footer-adresse">
          <h3 className="tertiary-title">Adresse</h3>
          <p>
            72 avenue du Progrès <br />
            69680 Chassieu
          </p>
        </div>
        <div className="footer-contact">
          <h3 className="tertiary-title">Contact</h3>
          <a href="tel:+33472133520" title="N'hésitez pas à nous appeler. 📞">
            04 72 13 35 20
          </a>{" "}
          <br />
          <a
            href="mailto:info@menuiseriebois.com"
            title="N'hésitez pas à nous contacter par mail.📧"
          >
            info@menuiseriebois.com
          </a>
        </div>
        <a
          className="footer-credit"
          href="https://bihua.fr/"
          target="_blank"
          rel="noreferrer"
        >
          Site réalisé par Bihua
        </a>
        <div className="footer-social">
          <a
            href="https://www.houzz.fr/professionnels/artisan-menuisier-et-ebeniste/perret-agencement-sur-mesure-pfvwfr-pf~1101281887"
            target="_blank"
            aria-label="Houzz"
            title="Rejoignez nous sur Houzz"
          >
            <svg
              width="41"
              height="41"
              viewBox="0 0 41 41"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="41" height="41" fill="#F3EBE2" />
              <path
                d="M12.0449 11.3611H16.5479V15.5791L28.9549 19.1131V29.6391H22.5899V23.5021H18.3529V29.6391H12.0449V11.3611Z"
                fill="#E58823"
              />
            </svg>
          </a>
          <a
            href="https://www.facebook.com/PERRET-SAS-171961649657978"
            target="_blank"
            aria-label="Facebook"
            title="Rejoignez nous sur Facebook"
          >
            <svg
              width="41"
              height="41"
              viewBox="0 0 41 41"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="41" height="41" fill="#F3EBE2" />
              <path
                d="M24.2492 11.004L21.7854 11C19.0173 11 17.2285 12.8353 17.2285 15.6759V17.8318H14.7512C14.5371 17.8318 14.3638 18.0054 14.3638 18.2195V21.3431C14.3638 21.5572 14.5373 21.7306 14.7512 21.7306H17.2285V29.6126C17.2285 29.8267 17.4018 30 17.6159 30H20.848C21.0621 30 21.2355 29.8265 21.2355 29.6126V21.7306H24.132C24.3461 21.7306 24.5194 21.5572 24.5194 21.3431L24.5206 18.2195C24.5206 18.1167 24.4797 18.0182 24.4071 17.9455C24.3346 17.8728 24.2358 17.8318 24.133 17.8318H21.2355V16.0042C21.2355 15.1258 21.4448 14.6799 22.589 14.6799L24.2488 14.6793C24.4627 14.6793 24.636 14.5058 24.636 14.2919V11.3914C24.636 11.1777 24.4629 11.0043 24.2492 11.004Z"
                fill="#E58823"
              />
            </svg>
          </a>
        </div>
        <button
          aria-label="Remonter en haut de la page"
          id="up"
          onClick={() => {
            console.log("ScrollTop dispatch");
            eventBus.dispatch("scrollTop");
            gsap.registerPlugin(ScrollToPlugin);
            gsap.to(window, { duration: 1, scrollTo: 0 });
          }}
        >
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="50" height="50" fill="#F3EBE2" />
            <path
              d="M18 25L19.2337 26.2337L24.125 21.3512V32H25.875V21.3512L30.7575 26.2425L32 25L25 18L18 25Z"
              fill="#E58823"
            />
          </svg>
        </button>
      </div>
    </footer>
  );
};

export default Footer;
