// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-api-batisseurs-js": () => import("./../../../src/pages/api-batisseurs.js" /* webpackChunkName: "component---src-pages-api-batisseurs-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-success-js": () => import("./../../../src/pages/contact-success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-la-menuiserie-js": () => import("./../../../src/pages/la-menuiserie.js" /* webpackChunkName: "component---src-pages-la-menuiserie-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-nos-valeurs-js": () => import("./../../../src/pages/nos-valeurs.js" /* webpackChunkName: "component---src-pages-nos-valeurs-js" */),
  "component---src-pages-realisations-amenagement-js": () => import("./../../../src/pages/realisations/amenagement.js" /* webpackChunkName: "component---src-pages-realisations-amenagement-js" */),
  "component---src-pages-realisations-boutiques-commerces-js": () => import("./../../../src/pages/realisations/boutiques-commerces.js" /* webpackChunkName: "component---src-pages-realisations-boutiques-commerces-js" */),
  "component---src-pages-realisations-cloisons-claustras-js": () => import("./../../../src/pages/realisations/cloisons-claustras.js" /* webpackChunkName: "component---src-pages-realisations-cloisons-claustras-js" */),
  "component---src-pages-realisations-collectivites-js": () => import("./../../../src/pages/realisations/collectivités.js" /* webpackChunkName: "component---src-pages-realisations-collectivites-js" */),
  "component---src-pages-realisations-index-js": () => import("./../../../src/pages/realisations/index.js" /* webpackChunkName: "component---src-pages-realisations-index-js" */),
  "component---src-pages-realisations-portes-fenetres-js": () => import("./../../../src/pages/realisations/portes-fenetres.js" /* webpackChunkName: "component---src-pages-realisations-portes-fenetres-js" */)
}

