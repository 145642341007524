// My custom event bus for comunicatijng stuff between page

const eventBus = {
    on(event, callback){
        console.log(event);
        document.addEventListener(event, (e) => callback(e.detail));
    }, 
    dispatch(event, data){
        document.dispatchEvent(new CustomEvent(event, { detail: data }));
    }, 
    remove(event, callback){
        document.removeEventListener(event, callback);
    }
};

export default eventBus
