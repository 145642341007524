import React from "react";
import { Link } from "gatsby";

import gsap from "gsap";
import imagesLoaded from "imagesloaded";

import "../styles/menu.scss";

import logoHouzz from "../images/houzz.png";
import logoFacebook from "../images/facebook.png";

import testingImg from "../images/testing-image.jpg";
import desktopBackCover from '../images/src/menu/menu_1_desktop.jpg';
import desktopBackCoverR from '../images/src/menu/menu_1_desktop@2x.jpg';
import desktopFrontCover from '../images/src/menu/menu_2_desktop.jpg';
import desktopFrontCoverR from '../images/src/menu/menu_2_desktop@2x.jpg';
import eventBus from "./utils/eventBus";

class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = { reaOpen: false, loaded: false };
  }
  componentDidMount() {
    gsap.set('.SubMenu', {display: 'none', opacity: 0});
    imagesLoaded("myNav", () => {
        this.setState({loaded: true});
      let tl = gsap.timeline();
      tl.fromTo(
        "#myNav",
        { opacity: 1, height: 0 },
        { opacity: 1, height: "100vh", duration: 1, ease: "power2.out" }
      );
      tl.fromTo(
        ["#myNav .list", "#myNav .social"],
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, ease: "power2.out" }
      );
      tl.to("#myNav .panel_hidder", {
        y: "-101vh",
        opacity: 1,
        delay: -0.6,
        duration: 1,
        ease: "power2.out",
      });
      tl.fromTo(
        "#myNav div:nth-child(2) picture:nth-child(2) img",
        { opacity: 0, scale: 1 },
        { opacity: 1, duration: 1, delay: -0.8, scale: 1.1 }
      );
    });

    eventBus.on("closeMenuAnim", () => {
      let tl = gsap.timeline();
      tl.to("#myNav .panel_hidder", { y: 0 });
      tl.to(["#myNav .list", "#myNav .social"], { y: -10, opacity: 0 });
      tl.to("#myNav", {
        height: 0,
        onComplete: () => {
          eventBus.dispatch("menuClose");
        },
      });
    });
  }

  openRea = (e) =>{
      if(!this.state.reaOpen  && this.state.loaded){
        this.setState({ reaOpen: true });
        let tl = gsap.timeline({
          duration: 0.1
        });
        tl.fromTo('.MainMenu li', {opacity: 1}, {opacity:0, stagger: 0.1, duration: 0.3});
        tl.set('.SubMenu', {display: 'flex', opacity: 1});
        tl.set('.MainMenu', {display: 'none', opacity: 0});
        tl.fromTo('.SubMenu .js-retour, .SubMenu .title', {opacity: 0}, {opacity:1, duration: 0.2});
        tl.fromTo('.SubMenu ul li', {opacity: 0}, {opacity:1, stagger: 0.1, duration: 0.3});
      }else{
        this.setState({ reaOpen: false });
        let tl = gsap.timeline();
        tl.fromTo('.SubMenu .js-retour', {opacity: 1}, {opacity:0, duration: 0.3});
        tl.fromTo('.SubMenu .title', {opacity: 1}, {opacity:0, duration: 0.3}, '-=0.4');
        tl.fromTo('.SubMenu ul li', {opacity: 1}, {opacity:0, stagger: 0.1, duration: 0.3});
        tl.set('.SubMenu', {display: 'none', opacity: 0});
        tl.set('.MainMenu', {display: 'block', opacity: 1});
        tl.fromTo('.MainMenu li', {opacity: 0}, {opacity:1, stagger: 0.1, duration: 0.6});
      }
  }
  render() {
    return (
      <div id="myNav" className="overlay">
        <div>
          <div className="list SubMenu">
            <p className="js-retour" onClick={()=>{this.openRea()}}>← retour</p>
            <p className="title">Nos réalisations</p>
            <ul>
              <li>
                <Link to="/realisations/collectivités">Bureaux et Collectivités</Link>
              </li>
              <li>
                <Link to="/realisations/cloisons-claustras">
                  Cloisons, Séparations et Claustras
                </Link>
              </li>
              <li>
                <Link to="/realisations/boutiques-commerces">
                  Boutiques et Commerces
                </Link>
              </li>
              <li>
                <Link to="/realisations/portes-fenetres">Portes et Fenêtres</Link>
              </li>
              <li>
                <Link to="/realisations/amenagement">
                  Aménagements Intérieurs
                </Link>
              </li>
            </ul>
          </div>
          <ul className="list MainMenu">
            <li>
              <Link to="/">Accueil</Link>
            </li>
            <li>
              <Link to="/la-menuiserie">La menuiserie</Link>
            </li>
            <li>
              <a id="Realisation"><span onClick={()=>{this.openRea()}}>Nos réalisations</span></a>
            </li>
            <li>
              <Link to="/nos-valeurs">Nos valeurs</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
          <ul className="social">
            <li>
              <a
                href="https://www.houzz.fr/professionnels/artisan-menuisier-et-ebeniste/perret-agencement-sur-mesure-pfvwfr-pf~1101281887"
                target="_blank"
                aria-label="Houzz"
                title="Rejoignez nous sur Houzz"
              >
                <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="41" height="41" fill="#E58823"/>
                <path d="M12.0449 11.3611H16.5479V15.5791L28.9549 19.1131V29.6391H22.5899V23.5021H18.3529V29.6391H12.0449V11.3611Z" fill="#F3EBE2"/>
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/PERRET-SAS-171961649657978"
                target="_blank"
                aria-label="Facebook"
                title="Rejoignez nous sur Facebook"
              >
                <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="41" height="41" fill="#E58823"/>
                <path d="M24.2492 11.004L21.7854 11C19.0173 11 17.2285 12.8353 17.2285 15.6759V17.8318H14.7512C14.5371 17.8318 14.3638 18.0054 14.3638 18.2195V21.3431C14.3638 21.5572 14.5373 21.7306 14.7512 21.7306H17.2285V29.6126C17.2285 29.8267 17.4018 30 17.6159 30H20.848C21.0621 30 21.2355 29.8265 21.2355 29.6126V21.7306H24.132C24.3461 21.7306 24.5194 21.5572 24.5194 21.3431L24.5206 18.2195C24.5206 18.1167 24.4797 18.0182 24.4071 17.9455C24.3346 17.8728 24.2358 17.8318 24.133 17.8318H21.2355V16.0042C21.2355 15.1258 21.4448 14.6799 22.589 14.6799L24.2488 14.6793C24.4627 14.6793 24.636 14.5058 24.636 14.2919V11.3914C24.636 11.1777 24.4629 11.0043 24.2492 11.004Z" fill="#F3EBE2"/>
                </svg>
              </a>
            </li>
          </ul>
        </div>
        <div>
          <picture>
            <source media="(min-width: 770px)" srcSet={desktopBackCoverR + ' 2x, ' + desktopBackCover + ' 1x'}/>
            <img src={desktopBackCover} srcSet={desktopBackCover + ' 2x, ' + desktopBackCover+ ' 1x'} alt="Image de test"></img>
          </picture>
          <picture>
          <source media="(min-width: 770px)" srcSet={desktopFrontCoverR + ' 2x, ' + desktopFrontCover + ' 1x'}/>
            <img src={desktopFrontCover} srcSet={desktopFrontCover + ' 2x, ' + desktopFrontCover+ ' 1x'} alt="Image de test"></img>
          </picture>
          <div className="panel_hidder"></div>
        </div>
      </div>
    );
  }
}

export default Menu;
