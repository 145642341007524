import * as React from "react";
import "../../styles/utils/headerTop.scss";
import { Link } from "gatsby";
import logo from "../../images/logo.svg";
import Menu from "../menu";
import eventBus from "../utils/eventBus.js";
import gsap from 'gsap';

const Header = ({location}) => {
  const [menu, setMenu] = React.useState(false);

  React.useEffect(()=>{
    gsap.fromTo('.header_component', {opacity:0}, {opacity: 1});
  }, [])

  React.useEffect(() => {
    if (menu) {
      document.querySelector("body").classList.add("menu_open");
      eventBus.dispatch("scrollStop");
    } else {
      document.querySelector("body").classList.remove("menu_open");
      eventBus.dispatch("enableScroll");
    }
    eventBus.on("menuClose", () => {
    console.log('This ievent is here')
      setMenu(false);
    });
  });

  return (
    <>
      <header className="header_component">
        <Link to="/" className="el-1">
          <picture>
            <source></source>
            <img src={logo} alt="Logo de la menuiserie Perret"></img>
          </picture>
        </Link>
        <p
          className={ ( !menu ? "el-2" : "el-2 white" ) + (  window.location.pathname == '/contact' ? " el-2 white contact" : '') + (!menu ? " menu" : " fermer")}
          onClick={() => {
            if(!menu){
              setMenu(true);
            }else{
              eventBus.dispatch('closeMenuAnim');
            }
          }}
        >
          <span>menu</span>
        </p>
      </header>
      {menu && <Menu />}
    </>
  );
};

export default Header;
