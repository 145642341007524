import * as React from "react";
import Helmet from "react-helmet";
import "../styles/layout.scss";
import Header from "./utils/header";
import Footer from "./utils/footer";
import { SwitchTransition, Transition } from "react-transition-group";
import gsap from "gsap";
import eventBus from "../components/utils/eventBus.js";
import imagesLoaded from "imagesloaded";
import ScrollTrigger from "gsap/ScrollTrigger";

export const ScrollStateContext = React.createContext(null);
class Layout extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
      obj: null,
      asscroll: null,
      gsap: null
  }

  componentDidMount() {
    document.getElementById("scroll-wrapper").classList.add("actif");
    gsap.registerPlugin(ScrollTrigger);
    const ASScroll = require("@ashthornton/asscroll");
    const asscroll = new ASScroll({
      disableRaf: true,
    });

    this.setState((state) => {
      return {
          gsap: gsap,
          obj: asscroll,
          scrollLevel: asscroll.currentPos,
      };
    });

    gsap.ticker.add(asscroll.update);

    ScrollTrigger.defaults({
      scroller: asscroll.containerElement,
    });

    ScrollTrigger.scrollerProxy(asscroll.containerElement, {
      scrollTop(value) {
        return arguments.length
          ? asscroll.scrollTo(value)
          : asscroll.currentPos;
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },
    });

    asscroll.on("update", ScrollTrigger.update);
    ScrollTrigger.addEventListener("refresh", asscroll.resize);

    // Verif height page every second
    let interval = setInterval(() => {
      asscroll.resize();
    }, 1000);

    // Trigger action on Smooth Scroll on Events
    eventBus.on("menuClose", () => {
      eventBus.dispatch("enableScroll");
    });

    eventBus.on("scrollTop", () => {
      asscroll.scrollTo(0, false);
    });

    eventBus.on("scrollStop", () => {
      eventBus.dispatch("scrollTop");
      console.log("scrollStop on");
      clearInterval(interval);
      setTimeout(() => {
        asscroll.disable();
        gsap.set("[asscroll]", { transform: "translate3d(0px, 0px, 0px)" });
      }, 200);
    });

    eventBus.on("enableScroll", () => {
      asscroll.enable();
      ScrollTrigger.update();
      interval = setInterval(() => {
        asscroll.resize();
        ScrollTrigger.update();
      }, 1000);

      /// ANIMATION SECTION (Présent ici car dépendant de Scroll Trigger)
      this.enableObservers();
      /* INDEX:  Introduction  */
      gsap.to("#introduction .parallax_elem", {
        scrollTrigger: {
          scrub: 0.2,
          trigger: "#introduction",
          end: "200%",
        },
        y: (i, target) => {
          return target.dataset.speed * 100;
        },
      });

      gsap.to(".bois h2 span", {
        scrollTrigger: {
          scrub: 0.2,
          trigger: ".bois",
          end: "200%",
        },
        y: (i, target) => Math.random() * (40 - -80 + 1) - 80,
      });

      /** Nos Valeurs */
      if (window.innerWidth > 770) {
        gsap.fromTo(
          "#formations .big-title",
          { marginLeft: window.innerWidth < 1500 ? "80%" : "100%" },
          {
            marginLeft: "-25%",
            scrollTrigger: {
              scrub: 0.1,
              trigger: "#formations",
              end: "150%",
            },
          }
        );
      }

      /** MENUISERIE */
      gsap.to("#profiles .parallax_elem", {
        scrollTrigger: {
          scrub: 0.2,
          trigger: "#profiles",
          end: "200%",
        },
        y: (i, target) => {
          return target.dataset.speed * (window.innerWidth > 770 ? 100 : 50);
        },
      });

      gsap.to("#tradition .parallax_elem", {
        scrollTrigger: {
          scrub: 0.2,
          trigger: "#tradition",
          end: "200%",
        },
        y: (i, target) => {
          return target.dataset.speed * (window.innerWidth > 770 ? 100 : 50);
        },
      });
    });

    /** FOOTER */
    /** if(window.innerWidth > 770) {
      gsap.to(".footer-left .back", {
        top: 0,
        width: "114%",
        marginRight: "-32px",
        rotate: 0,
        left: 0,
        scrollTrigger: {
          scrub: true,
          trigger: "footer",
          end: "50%",
        },
      });
    } **/

    asscroll.enable();
  }

  componentDidUpdate(){

  }

  enableObservers() {
    /** Animation de block de text on viewport */
    document.querySelectorAll(".content-animation").forEach((target) => {
      if (target) {
        let prevRatio = 0.0;
        let prevY = 0.0;

        const callback = (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.intersectionRatio > prevRatio) {
              // Enter by top
              if (entry.boundingClientRect.y > prevY) {
                let tl = gsap.timeline();
                tl.fromTo(
                  entry.target.querySelector(".title-anim"),
                  { opacity: 0, y: 10 },
                  { opacity: 1, y: 0, duration: 1 }
                );
                tl.fromTo(
                  entry.target.querySelector("p"),
                  { opacity: 0 },
                  { opacity: 1 }
                );
                tl.fromTo(
                  entry.target.querySelector(".Button"),
                  { opacity: 0 },
                  { opacity: 1 }
                );
                observer.unobserve(target);
              }
            } else {
            }
          });
        };
        let observer = new IntersectionObserver(callback);
        observer.observe(target);
      }
    });

    /** Animation de liste déroulant au viewport*/
    document.querySelectorAll("li.js_details").forEach((target) => {
      if (target) {
        let prevRatio = 0.0;
        let prevY = 0.0;

        const callback = (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.intersectionRatio > prevRatio) {
              // Enter by top
              if (entry.boundingClientRect.y > prevY) {
                if (window.innerwidth < 770) {
                  let tl = gsap.timeline();
                  tl.fromTo(
                    entry.target,
                    { width: 0, y: 10 },
                    { width: "100%", y: 0, duration: 1 }
                  );
                  tl.fromTo(
                    entry.target.querySelector("summary div"),
                    { opacity: 0, y: 10 },
                    { opacity: 1, y: 0, duration: 1 }
                  );
                }
                observer.unobserve(target);
              }
            } else {
            }
          });
        };
        let observer = new IntersectionObserver(callback);
        observer.observe(target);
      }
    });

    /** Animation d'apparition classique d'image on viewport */
    document.querySelectorAll("picture.js_intersection").forEach((target) => {
      if (target) {
        let prevRatio = 0.0;
        let prevY = 0.0;

        const callback = (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.intersectionRatio > prevRatio) {
              // Enter by top
              if (entry.boundingClientRect.y > prevY) {
                switch (entry.target.dataset.anim) {
                  case "classic":
                    if (window.innerWidth > 770) {
                      gsap.fromTo(
                        entry.target,
                        { x: -20, opacity: 0 },
                        { x: 0, opacity: 1, duration: 1, delay: 0.2 }
                      );
                    }
                    break;

                  default:
                    break;
                }
                observer.unobserve(target);
              }
            } else {
            }
          });
        };
        let observer = new IntersectionObserver(callback);
        observer.observe(target);
      }
    });
  }

  render() {
    return (
      <>
        <Helmet
          htmlAttributes={{
            lang: "fr",
          }}
        />

        <div id="Modal"></div>
        <div id="scroll-wrapper" asscroll-container="true">
          <div>
            <Header />
            <SwitchTransition mode={"out-in"}>
              <Transition
                key={Math.random()}
                appear={false}
                in={true}
                unmountOnExit
                timeout={1000}
                onEnter={(node) => {
                  console.log("OnEnter Page");
                  console.log(node);
                  console.log(this.props);
                  eventBus.dispatch("menuClose", { message: "test" });
                  gsap.killTweensOf("#Panel");
                  imagesLoaded("main", () => {
                    gsap.fromTo('body', {opacity: 0}, {opacity: 1})
                    let truck = 0;
                    let duration = 1;
                    gsap.killTweensOf([".panel__left", "panel__right", "main"]);
                    gsap.fromTo(
                      "main",
                      { opacity: 0 },
                      { opacity: 1, duration: 0.3 }
                    );
                    gsap.fromTo(
                      "#Panel .panel__left",
                      { y: 0 },
                      { y: "-200vw", duration: duration }
                    );
                    gsap.fromTo(
                      "#Panel .panel__right",
                      { y: 0 },
                      {
                        y: "200vw",
                        duration: duration,
                        onComplete: () => {
                          console.log(truck);
                          truck++;
                          if (document.querySelector(".initialise")) {
                            document
                              .querySelector(".initialise")
                              .classList.remove("initialise");
                          }
                          eventBus.dispatch("Finalised");
                        },
                      }
                    );
                  });
                }}
                addEndListener={(node) => {
                  console.log(node);
                  console.log("Entyer Animation");
                }}
                onExit={() => {
                  console.log("leave Animation");
                  gsap.fromTo(
                    "main",
                    { opacity: 1 },
                    { opacity: 0, duration: 0.3 }
                  );
                  gsap.fromTo("#Panel .panel__left", { y: "-200vw" }, { y: 1 });
                  gsap.fromTo(
                    "#Panel .panel__right",
                    { y: "200vw" },
                    { y: -1 }
                  );
                  eventBus.dispatch("scrollTop");
                }}
              >
                <main id={this.props.location.pathname} className="main">
                  <ScrollStateContext.Provider value={this.state}>
                    {this.props.children}
                  </ScrollStateContext.Provider>
                </main>
              </Transition>
            </SwitchTransition>
            <Footer />
          </div>
        </div>

        <div id="Panel" className="initialise">
          <div className="panel__left panel"></div>
          <div className="panel__right panel"></div>
        </div>
      </>
    );
  }
}

export default Layout;
